export const NFTContract_ABI = [{
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "components": [{
            "internalType": "string",
            "name": "rarity",
            "type": "string"
        }, {
            "internalType": "uint256",
            "name": "maxSupply",
            "type": "uint256"
        }, {
            "internalType": "uint256",
            "name": "totalSupply",
            "type": "uint256"
        }, {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
        }, {
            "internalType": "address",
            "name": "beneficiary",
            "type": "address"
        }, {
            "internalType": "string",
            "name": "metadata",
            "type": "string"
        }, {
            "internalType": "string",
            "name": "contentHash",
            "type": "string"
        }],
        "indexed": false,
        "internalType": "struct ERC721BaseCollectionV2.Item",
        "name": "_item",
        "type": "tuple"
    }],
    "name": "AddItem",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "approved",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "Approval",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "operator",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
    }],
    "name": "ApprovalForAll",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "string",
        "name": "_oldBaseURI",
        "type": "string"
    }, {
        "indexed": false,
        "internalType": "string",
        "name": "_newBaseURI",
        "type": "string"
    }],
    "name": "BaseURI",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [],
    "name": "Complete",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "_previousCreator",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "_newCreator",
        "type": "address"
    }],
    "name": "CreatorshipTransferred",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "_beneficiary",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "uint256",
        "name": "_tokenId",
        "type": "uint256"
    }, {
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "_issuedId",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "address",
        "name": "_caller",
        "type": "address"
    }],
    "name": "Issue",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "address",
        "name": "relayerAddress",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bytes",
        "name": "functionSignature",
        "type": "bytes"
    }],
    "name": "MetaTransactionExecuted",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
    }],
    "name": "OwnershipTransferred",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "string",
        "name": "_contentHash",
        "type": "string"
    }, {
        "indexed": false,
        "internalType": "string",
        "name": "_metadata",
        "type": "string"
    }],
    "name": "RescueItem",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "bool",
        "name": "_previousValue",
        "type": "bool"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "_newValue",
        "type": "bool"
    }],
    "name": "SetApproved",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": false,
        "internalType": "bool",
        "name": "_previousValue",
        "type": "bool"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "_newValue",
        "type": "bool"
    }],
    "name": "SetEditable",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "_manager",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "_value",
        "type": "bool"
    }],
    "name": "SetGlobalManager",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "_minter",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "_value",
        "type": "bool"
    }],
    "name": "SetGlobalMinter",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "_manager",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "bool",
        "name": "_value",
        "type": "bool"
    }],
    "name": "SetItemManager",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "_minter",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "_value",
        "type": "uint256"
    }],
    "name": "SetItemMinter",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "indexed": true,
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "Transfer",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{
        "indexed": true,
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "uint256",
        "name": "_price",
        "type": "uint256"
    }, {
        "indexed": false,
        "internalType": "address",
        "name": "_beneficiary",
        "type": "address"
    }, {
        "indexed": false,
        "internalType": "string",
        "name": "_metadata",
        "type": "string"
    }],
    "name": "UpdateItemData",
    "type": "event"
}, {
    "inputs": [],
    "name": "COLLECTION_HASH",
    "outputs": [{
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "ISSUED_ID_BITS",
    "outputs": [{
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "ITEM_ID_BITS",
    "outputs": [{
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "MAX_ISSUED_ID",
    "outputs": [{
        "internalType": "uint216",
        "name": "",
        "type": "uint216"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "MAX_ITEM_ID",
    "outputs": [{
        "internalType": "uint40",
        "name": "",
        "type": "uint40"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "components": [{
            "internalType": "string",
            "name": "rarity",
            "type": "string"
        }, {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
        }, {
            "internalType": "address",
            "name": "beneficiary",
            "type": "address"
        }, {
            "internalType": "string",
            "name": "metadata",
            "type": "string"
        }],
        "internalType": "struct ERC721BaseCollectionV2.ItemParam[]",
        "name": "_items",
        "type": "tuple[]"
    }],
    "name": "addItems",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }],
    "name": "balanceOf",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "baseURI",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "_from",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "_to",
        "type": "address"
    }, {
        "internalType": "uint256[]",
        "name": "_tokenIds",
        "type": "uint256[]"
    }],
    "name": "batchTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "completeCollection",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "createdAt",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "creator",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "_id",
        "type": "uint256"
    }],
    "name": "decodeTokenId",
    "outputs": [{
        "internalType": "uint256",
        "name": "itemId",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "issuedId",
        "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
}, {
    "inputs": [],
    "name": "domainSeparator",
    "outputs": [{
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256[]",
        "name": "_itemIds",
        "type": "uint256[]"
    }, {
        "internalType": "uint256[]",
        "name": "_prices",
        "type": "uint256[]"
    }, {
        "internalType": "address[]",
        "name": "_beneficiaries",
        "type": "address[]"
    }, {
        "internalType": "string[]",
        "name": "_metadatas",
        "type": "string[]"
    }],
    "name": "editItemsData",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "_itemId",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "_issuedId",
        "type": "uint256"
    }],
    "name": "encodeTokenId",
    "outputs": [{
        "internalType": "uint256",
        "name": "id",
        "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
    }, {
        "internalType": "bytes",
        "name": "functionSignature",
        "type": "bytes"
    }, {
        "internalType": "bytes32",
        "name": "sigR",
        "type": "bytes32"
    }, {
        "internalType": "bytes32",
        "name": "sigS",
        "type": "bytes32"
    }, {
        "internalType": "uint8",
        "name": "sigV",
        "type": "uint8"
    }],
    "name": "executeMetaTransaction",
    "outputs": [{
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
    }],
    "stateMutability": "payable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "getApproved",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "getChainId",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "pure",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "user",
        "type": "address"
    }],
    "name": "getNonce",
    "outputs": [{
        "internalType": "uint256",
        "name": "nonce",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "globalManagers",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "globalMinters",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "initImplementation",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "string",
        "name": "_name",
        "type": "string"
    }, {
        "internalType": "string",
        "name": "_symbol",
        "type": "string"
    }, {
        "internalType": "string",
        "name": "_baseURI",
        "type": "string"
    }, {
        "internalType": "address",
        "name": "_creator",
        "type": "address"
    }, {
        "internalType": "bool",
        "name": "_shouldComplete",
        "type": "bool"
    }, {
        "internalType": "bool",
        "name": "_isApproved",
        "type": "bool"
    }, {
        "internalType": "contract IRarities",
        "name": "_rarities",
        "type": "address"
    }, {
        "components": [{
            "internalType": "string",
            "name": "rarity",
            "type": "string"
        }, {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
        }, {
            "internalType": "address",
            "name": "beneficiary",
            "type": "address"
        }, {
            "internalType": "string",
            "name": "metadata",
            "type": "string"
        }],
        "internalType": "struct ERC721BaseCollectionV2.ItemParam[]",
        "name": "_items",
        "type": "tuple[]"
    }],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "isApproved",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [{
            "internalType": "address",
            "name": "owner",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "operator",
            "type": "address"
        }
    ],
    "name": "isApprovedForAll",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "isCompleted",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "isEditable",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "isInitialized",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "isMintingAllowed",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address[]",
        "name": "_beneficiaries",
        "type": "address[]"
    }, {
        "internalType": "uint256[]",
        "name": "_itemIds",
        "type": "uint256[]"
    }],
    "name": "issueTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "itemManagers",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }, {
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "itemMinters",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "name": "items",
    "outputs": [{
        "internalType": "string",
        "name": "rarity",
        "type": "string"
    }, {
        "internalType": "uint256",
        "name": "maxSupply",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "totalSupply",
        "type": "uint256"
    }, {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
    }, {
        "internalType": "address",
        "name": "beneficiary",
        "type": "address"
    }, {
        "internalType": "string",
        "name": "metadata",
        "type": "string"
    }, {
        "internalType": "string",
        "name": "contentHash",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "itemsCount",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "name",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "owner",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "ownerOf",
    "outputs": [{
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "rarities",
    "outputs": [{
        "internalType": "contract IRarities",
        "name": "",
        "type": "address"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256[]",
        "name": "_itemIds",
        "type": "uint256[]"
    }, {
        "internalType": "string[]",
        "name": "_contentHashes",
        "type": "string[]"
    }, {
        "internalType": "string[]",
        "name": "_metadatas",
        "type": "string[]"
    }],
    "name": "rescueItems",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "_from",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "_to",
        "type": "address"
    }, {
        "internalType": "uint256[]",
        "name": "_tokenIds",
        "type": "uint256[]"
    }, {
        "internalType": "bytes",
        "name": "_data",
        "type": "bytes"
    }],
    "name": "safeBatchTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "from",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
},
{
    "inputs": [{
            "internalType": "address",
            "name": "from",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }, {
            "internalType": "bytes",
            "name": "_data",
            "type": "bytes"
        }
    ],
    "name": "safeTransferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "operator",
        "type": "address"
    }, {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
    }],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "bool",
        "name": "_value",
        "type": "bool"
    }],
    "name": "setApproved",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "string",
        "name": "_baseURI",
        "type": "string"
    }],
    "name": "setBaseURI",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "bool",
        "name": "_value",
        "type": "bool"
    }],
    "name": "setEditable",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256[]",
        "name": "_itemIds",
        "type": "uint256[]"
    }, {
        "internalType": "address[]",
        "name": "_managers",
        "type": "address[]"
    }, {
        "internalType": "bool[]",
        "name": "_values",
        "type": "bool[]"
    }],
    "name": "setItemsManagers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256[]",
        "name": "_itemIds",
        "type": "uint256[]"
    }, {
        "internalType": "address[]",
        "name": "_minters",
        "type": "address[]"
    }, {
        "internalType": "uint256[]",
        "name": "_values",
        "type": "uint256[]"
    }],
    "name": "setItemsMinters",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address[]",
        "name": "_managers",
        "type": "address[]"
    }, {
        "internalType": "bool[]",
        "name": "_values",
        "type": "bool[]"
    }],
    "name": "setManagers",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address[]",
        "name": "_minters",
        "type": "address[]"
    }, {
        "internalType": "bool[]",
        "name": "_values",
        "type": "bool[]"
    }],
    "name": "setMinters",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "bytes4",
        "name": "interfaceId",
        "type": "bytes4"
    }],
    "name": "supportsInterface",
    "outputs": [{
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "symbol",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
    }],
    "name": "tokenByIndex",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
    }],
    "name": "tokenOfOwnerByIndex",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "uint256",
        "name": "_tokenId",
        "type": "uint256"
    }],
    "name": "tokenURI",
    "outputs": [{
        "internalType": "string",
        "name": "",
        "type": "string"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "_newCreator",
        "type": "address"
    }],
    "name": "transferCreatorship",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "from",
        "type": "address"
    }, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
    }],
    "name": "transferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
    }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}
]